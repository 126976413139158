// import { UserRegisterView } from './app/site/views/user/UserRegisterView';
import { App } from './app/App';
import { DropdownView } from './app/site/views/DropdownView';
import { NavView } from './app/site/views/NavView';

import { CopyTextInputView } from './app/site/views/CopyTextInputView';
import { SiteHeaderView } from './app/site/views/SiteHeaderView';
import { PostLazyImageView } from './app/site/views/PostLazyImageView';
import { TransparentPostLinkView } from './app/site/views/TransparentPostLinkView';
import { QuickSearchFormView } from './app/site/views/QuickSearchFormView';

/*async function statCmp() {
    const statBundle = await import('./app/components/internal/specific/StatComponent');
    return (statBundle).StatComponent;
}*/

const app = new App();
const navView: { value?: NavView; } = {};

app.viewFactory.setFactoryFn('dropdown', async (vEl) => new DropdownView(vEl));
app.viewFactory.setFactoryFn('nav', async (vEl) => (navView.value = (navView.value ?? (new NavView(vEl)))));

app.viewFactory.setFactoryFn('copyTextInput', async (vEl) => new CopyTextInputView(vEl));

app.viewFactory.setFactoryFn('siteHeader', async (vEl) => new SiteHeaderView(vEl));
app.viewFactory.setFactoryFn('postLazyImage', async (vEl) => new PostLazyImageView(vEl));
app.viewFactory.setFactoryFn('transparentPostLink', async (vEl) => new TransparentPostLinkView(vEl));
app.viewFactory.setFactoryFn('quickSearchForm', async (vEl) => new QuickSearchFormView(vEl as HTMLFormElement, app.formValidationInteractor));



app.metaCallback = (meta) => {
    if (navView.value && 'nav_active_link' in meta) {
        navView.value.setActive(meta['nav_active_link']);
    }
};

//
app.activate();

/*const unloadFn = () => {
    window.removeEventListener('unload', unloadFn);
    app.destroy();
};
window.addEventListener('unload', unloadFn);*/
