import { LiveInteractor } from '../../interactors/LiveInteractor';
import { Component } from '../Component';
import { AppView } from '../../views/AppView';
import { InjectView } from '../../views/InjectView';
import { ViewFactory } from '../../views/ViewFactory';
import { ProgressBarView } from "../../views/ProgressBarView";
import { LiveLoadingErrorView } from "../../views/LiveLoadingErrorView";

const CANONICAL_URL = (() => {
    const el = document.head.querySelector<HTMLLinkElement>('link[rel=canonical][href]');
    return new URL(el!.href, document.location.href);
})();

function eqURL(u1: URL, u2: URL) {
    return (u1.pathname + u1.search) === (u2.pathname + u2.search);
}

type NavParams = {
    url: URL;
    pushState: boolean;
    method: "GET" | "POST";
    data?: FormData | URLSearchParams;
};

const INTERVAL = 25;
const AVG_RESP_TIME = 500;

export class LiveNavigationComponent extends Component {
    protected abortCtl?: AbortController;
    protected isProcessing: boolean;
    protected isDone: boolean;
    protected isDataDownloaded: boolean;
    protected isFailed?: boolean;
    protected currentURL?: URL;
    protected popState = this.handlePopStateEvent.bind(this);
    protected injectView?: InjectView;
    protected progressBarView?: ProgressBarView;
    protected loadingErrorView?: LiveLoadingErrorView;
    protected startTime?: number;
    protected checkpointParams?: NavParams;

    constructor(protected readonly liveInteractor: LiveInteractor,
        protected readonly viewFactory: ViewFactory,
        protected readonly appView: AppView) {
        super();
        this.isProcessing = false;
        this.isDone = true;
        this.isDataDownloaded = true;
    }

    public async buildInjectView(vEl: HTMLElement) {
        if (this.injectView) {
            throw new Error('Inject view is already created');
        }
        return (this.injectView = new InjectView(vEl, this.viewFactory));
    }

    public async buildProgressBarView(vEl: HTMLElement) {
        if (this.progressBarView) {
            throw new Error('ProgressBarView is already created');
        }
        return (this.progressBarView = new ProgressBarView(vEl));
    }

    public async buildLiveLoadingErrorView(vEl: HTMLElement) {
        if (this.loadingErrorView) {
            throw new Error('LiveLoadingErrorView is already created');
        }
        return (this.loadingErrorView = new LiveLoadingErrorView(vEl, this));
    }

    activate(): void {
        window.addEventListener('popstate', this.popState);

        // заменяем состояние текущим адресом страницы
        this.historyStateUpdate(CANONICAL_URL, 'replace');
    }

    destroy(): void {
        window.removeEventListener('popstate', this.popState);
        this.loadingErrorView?.hide();
    }

    public async navigateTo(params: NavParams): Promise<void> {
        if (!this.injectView) {
            throw new Error('Inject view ref is not set');
        }

        console.log('navigateTo=', params);
        if (this.isProcessing ||
            this.viewFactory.isLoading) {
            return;
        }
        this.isProcessing = true;
        this.isDone = false;
        this.isDataDownloaded = false;
        this.isFailed = false;
        this.checkpointParams = params;
        this.loadingErrorView?.hide();

        const signal = (this.abortCtl = new AbortController()).signal;
        try {
            this.updateProgress();

            const liveData = await this.liveInteractor.fetchPage({
                url: params.url.href,
                method: params.method,
                body: ('data' in params ? params.data : undefined),
                signal
            });

            this.isDataDownloaded = true;
            this.updateProgress();

            if ('errorMessage' in liveData) {
                throw new Error(liveData.errorMessage ?? '');
            }

            const realURL = new URL(liveData.canonicalUrl);

            //if (realURL.pathname !== params.url.pathname) {
            //    // reload page fully...
            //    console.log('reload page fully...', realURL.pathname, params.url.pathname);
            //    window.location.href = params.url.href;
            //    return;
            //}
            //
            if (params.pushState && CANONICAL_URL.origin === params.url.origin) {
                this.historyStateUpdate(realURL, 'push');
            }
            //
            await this.injectView.replaceContent(liveData.content);
            this.appView.setTitle(liveData.title);
            delete this.checkpointParams;
            //
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } catch (e) {
            console.log(e);
            if (!signal.aborted) {
                this.isFailed = true;
                this.loadingErrorView?.displayError(e);
            }
        } finally {
            delete this.abortCtl;
            this.isProcessing = false;
            this.isDone = true;
            this.updateProgress();
        }
    }

    public async retryFailedAttempt() {
        if (this.isFailed && this.checkpointParams) {
            await this.navigateTo(this.checkpointParams);
        }
    }

    protected updateProgress(): void {
        if (this.isDone) {
            // 100%
            console.log('DONE');
            if (this.startTime) {
                delete this.startTime;
                this.progressBarView?.update(100);
            } else {
                this.progressBarView?.update(0);
            }
        } else if (this.isDataDownloaded) {
            // 85..%
            console.log('data avail');
            this.progressBarView?.update(85);
        } else if (this.isProcessing) {
            // 20-85%

            this.startTime = this.startTime ?? performance.now();
            const diff = performance.now() - this.startTime,
                dt = Math.min(diff, AVG_RESP_TIME),
                p = 20 + (65 * (dt / AVG_RESP_TIME));

            this.progressBarView?.update(p);
            setTimeout(this.updateProgress.bind(this), INTERVAL);
        }
    }

    public cancelLoading(): void {
        if (this.isProcessing) {
            this.abortCtl?.abort();
            this.isProcessing = false;
            this.loadingErrorView?.hide();
        }
    }

    protected historyStateUpdate(url: URL, act: "push" | "replace"): void {
        const fn = window.history[act === 'push' ? 'pushState' : 'replaceState'];
        const state = url.pathname + url.search;

        if (!this.currentURL || !eqURL(this.currentURL!, url)) {
            this.currentURL = url;
            fn.call(window.history,
                state, '', url.href);
        }
    }

    protected handlePopStateEvent(ev: PopStateEvent): void {
        if (ev.state) {
            this.currentURL = new URL(ev.state, CANONICAL_URL);

            this.cancelLoading();
            this.navigateTo({
                method: 'GET',
                url: new URL(ev.state, CANONICAL_URL),
                pushState: false
            });
        }
    }
}

