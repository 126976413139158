import { MvpView } from './MvpView';
export class Presenter<V extends MvpView<Presenter<V, M>>, M = any> {
    protected view?: V;
    constructor(public model?: M) {
    }
    public onReady(view: V) {
        this.view = view;
    }
    public onDisposed() {
        this.view = undefined;
    }
    protected ifViewAttached(fn: ((v: V) => void)) {
        if (this.view && !this.view.isDisposed) {
            fn(this.view);
        }
    }
}
