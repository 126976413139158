import { View } from "../../views/View";

export class PostLazyImageView extends View<any> {
    constructor(el: HTMLElement) {
        super('postLazyImage', el);
    }
    public init(): void {
        super.init();

        // Lazy images
        let imageUrl;
        if ((imageUrl = this.el.getAttribute("data-image-url"))) {

            this.el.style.backgroundImage = "url(" + imageUrl + ")";
        }

    }
    public dispose(): void {
        super.dispose();
    }
}

