import { LiveNavigationComponent } from "../components/internal/LiveNavigationComponent";
import { View } from "./View";

type LiveLoadingErrorState = {
    defaultMessage: string;
};

export class LiveLoadingErrorView extends View<LiveLoadingErrorState> {
    protected msgEl?: HTMLElement;
    protected reloadBtnEl?: HTMLElement;
    protected reloadClickEventListener = (ev: MouseEvent) => this.doReload.call(this, ev);
    protected containerClickEventListener = () => this.hide.call(this);

    constructor(el: HTMLElement,
        protected liveNavComponent: LiveNavigationComponent) {
        super('liveLoadingError', el, {
            defaultMessage: el.getAttribute('data-message') ?? ''
        }, false);
    }

    public init(): void {
        this.msgEl = this.el!.querySelector<HTMLElement>('*[rel=message]')!;
        this.reloadBtnEl = this.el!.querySelector<HTMLElement>('*[rel=reload]')!;

        //
        super.init();
        //
        this.reloadBtnEl?.addEventListener('click', this.reloadClickEventListener);
        this.el?.addEventListener('click', this.containerClickEventListener);
        this.hide();
    }
    public dispose(): void {
        super.dispose();
        this.reloadBtnEl?.removeEventListener('click', this.reloadClickEventListener);
        this.el?.removeEventListener('click', this.containerClickEventListener);
    }
    displayError(e: any): void {
        if (this.msgEl) {
            this.msgEl.textContent = e.message ?? this.state?.defaultMessage ?? "";
        }
        this.el!.classList.remove('hide');
    }
    hide(): void {
        this.el!.classList.add('hide');
    }

    doReload(ev: MouseEvent): void {
        ev.preventDefault();
        console.log('reload!!!');
        // window.location.reload();
        this.liveNavComponent.retryFailedAttempt();
    }

}
