import { CaptchaInteractor } from "../../interactors/CaptchaInteractor";
import { MvpView } from "../../mvp/MvpView";
import { CaptchaPresenter } from "../presenter/CaptchaPresenter";

type CaptchaState = {
    target: string;
};

export class CaptchaView extends MvpView<CaptchaPresenter, CaptchaState> {
    protected imageEl: HTMLImageElement | null | undefined;
    protected reloadLinkEl: HTMLElement | null | undefined;
    protected chEl: HTMLElement | null | undefined;

    protected reloadClickEventListener: ((ev: MouseEvent) => void)
        = this.handleReloadImageLinkClick.bind(this);

    constructor(el: HTMLElement,
        protected readonly captchaInteractor: CaptchaInteractor) {
        super('captcha', el, undefined, {
            target: ""
        });
    }
    protected createPresenter(): CaptchaPresenter {
        return new CaptchaPresenter(this.state!.target!, this.captchaInteractor);
    }
    public init(): void {
        const formEl = this.el.closest('form')!;
        //
        this.imageEl = this.el.querySelector<HTMLImageElement>('img.captcha-image');
        this.reloadLinkEl = this.el.querySelector<HTMLElement>('.reload-image-link');
        this.chEl = formEl.querySelector<HTMLElement>('input[type=hidden][name*="CAPTCHA_CHALLENGE"]');
        //
        if (this.el.hasAttribute('data-target')) {
            this.state!.target = this.el.getAttribute('data-target')!;
        } else {
            this.state!.target = formEl.getAttribute('name')!;
        }
        //
        super.init();
        //
        this.reloadLinkEl?.addEventListener('click', this.reloadClickEventListener);
    }
    public dispose(): void {
        super.dispose();
        this.reloadLinkEl?.removeEventListener('click', this.reloadClickEventListener);
        delete this.imageEl, this.reloadLinkEl, this.chEl;
    }
    private handleReloadImageLinkClick(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.presenter?.reloadImage();
    }
    public setCaptcha(challengeString: string, image: HTMLImageElement): void {

        this.chEl?.setAttribute('value', challengeString);
        this.imageEl?.setAttribute('src', image.getAttribute('src')!);
    }
}
