import { View } from "./View";
import { ViewFactory } from "./ViewFactory";

export class InjectView extends View {

    constructor(el: HTMLElement,
        protected readonly viewFactory: ViewFactory) {
        super('inject', el, undefined, true);
    }
    public async replaceContent(content: string) {
        const el = document.createElement('div');

        // dispose all child views...
        if (this.childList.length) {
            this.childList.forEach(v => {
                try {
                    v.dispose();
                } catch (e) { }
            });
            this.childList.length = 0;
        }

        // create empty <div> and put HTML...
        el.insertAdjacentHTML('afterbegin', content);
        // replace "inject" view content
        this.el.replaceChildren(el);
        // create DOM views
        await this.instantiateChildViews(this.viewFactory, el);
        this.childList.forEach(v => v.init());
        return el;
    }
}

