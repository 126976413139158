import { ViewManager } from './ViewManager';
import { ViewFactory } from './ViewFactory';

export class View<S = any> {
    public readonly id: number;
    public readonly childList: Array<View>;
    protected isInit?: boolean;
    protected _isDisposed?: boolean;
    protected state?: S;

    public constructor(public readonly name: string,
        public readonly el: HTMLElement,
        initialState?: S,
        protected subTree: boolean = false) {

        this.childList = [];

        if (View.vid(el)) {
            throw new Error('element is already bound to view');
        }
        this.id = ViewManager.instance.addInst(this);
        this.el.setAttribute('data-vid', String(this.id));
        //
        this.state = initialState ?? this.buildInitialState();
    }
    public init(): void {
        if (this.isDisposed) {
            throw new Error('view is already disposed');
        } else if (this.isInit) {
            return;
        }
        this.isInit = true;
        this.childList.forEach(v => v.init());
    }
    get isDisposed(): boolean { return !!this._isDisposed; }
    public dispose(): void {
        if (this.isInit && !this._isDisposed) {
            this._isDisposed = true;
            this.childList.forEach(v => v.dispose());
            this.childList.length = 0;
            ViewManager.instance.removeInst(this);
        }
        delete this.state;
    }

    public async instantiateChildViews(viewFactory: ViewFactory, el?: Node) {
        let node, v;
        if (!this.subTree) {
            return;
        }
        node = (el ?? this.el).firstChild;
        while (node) {
            if ((node instanceof HTMLElement) &&
                node.getAttribute('data-view') && !View.vid(node)) {
                // если указан компонент, то ниже будет 
                // спускаться уже инстанс этого компонента (View)...
                this.childList.push((v = await View.newInstEl(viewFactory, node)));
                await v.instantiateChildViews(viewFactory, node);
            } else {
                await this.instantiateChildViews(viewFactory, node);
            }

            node = node.nextSibling;
        }
    }

    public static async newInstEl(viewFactory: ViewFactory, vEl: HTMLElement): Promise<View> {
        const name = vEl.getAttribute('data-view')!;
        return await viewFactory.build(name, vEl);
    }

    public static vid(el: HTMLElement): number | undefined {
        const str = el.getAttribute('data-vid');
        return str ? parseInt(str) : undefined;
    }
    public static ofEl(el: HTMLElement): View | null {
        const id = View.vid(el);
        return id ? ViewManager.instance?.getById(id).inst : null;
    }

    //
    protected buildInitialState(): S | undefined {
        return undefined;
    }
    protected mutateState(mutation: any): void {
        // use object "mutation" to modify this.state.
        mutation && null;
    }
    public static mutateStateFromEl(v: View, el: HTMLElement): void {
        let s: object | undefined;
        // data-state='{"test":true}'
        if (el.hasAttribute('data-state')) {
            try {
                s = JSON.parse(el.getAttribute('data-state')!);
            } catch (e: any) {
            }
        } else if ('dataset' in el) {
            // data-param1="value" data-test="OK"
            s = Object.assign({}, el.dataset);
        }
        v.mutateState(s);
    }
}
