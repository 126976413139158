import { GenericApiClient } from "../ApiClient";
import { ApiMethod } from "../enums/ApiEnums";

type TokenResponse = {
    "ACCESS_TOKEN": {
        "TOKEN": string;
        "EXPIRES_IN": number;
    };
};
type TokenData = {
    accessToken: string;
    expiresIn: number;
};

export class TokenInteractor {
    constructor(protected readonly apiClient: GenericApiClient) {
    }
    async refreshToken(refreshToken: string): Promise<TokenData> {
        const body = new FormData();
        body.set('REFRESH_TOKEN', refreshToken);
        const responseData = await this.apiClient.callMethod<TokenResponse>({
            method: ApiMethod.REFRESH_TOKEN,
            body
        });
        return {
            accessToken: responseData['ACCESS_TOKEN']['TOKEN'],
            expiresIn: responseData['ACCESS_TOKEN']['EXPIRES_IN']
        };
    }

}
