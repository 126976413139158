import { GenericApiClient } from "./ApiClient";
import { Component } from "./components/Component";
import { AuthComponent } from "./components/internal/AuthComponent";
import { LiveNavigationComponent } from "./components/internal/LiveNavigationComponent";
import { CaptchaView } from "./forms/view/CaptchaView";
import { FormView } from "./forms/view/FormView";
import { processMetaParamsCallbackMap } from "./helpers/MetaHelper";
import { ApiInteractor } from "./interactors/ApiInteractor";
import { CaptchaInteractor } from "./interactors/CaptchaInteractor";
import { FormValidationInteractor } from "./interactors/FormValidationInteractor";
import { LiveInteractor } from "./interactors/LiveInteractor";
import { TokenInteractor } from "./interactors/TokenInteractor";
import { AlertsView } from "./views/AlertsView";
import { AppView } from "./views/AppView";
import { LiveView } from "./views/LiveView";
import { ViewFactory } from "./views/ViewFactory";

export class App extends Component {
    public readonly viewFactory: ViewFactory;
    public readonly apiClient: GenericApiClient;
    public readonly authComponent: AuthComponent;
    public readonly apiClientAuth: GenericApiClient;
    public readonly captchaInteractor: CaptchaInteractor;
    public readonly formValidationInteractor: FormValidationInteractor;
    public readonly liveInteractor: LiveInteractor;
    public readonly appView: AppView;
    public readonly liveNavComponent: LiveNavigationComponent;
    public metaCallback?: (meta: { [K: string]: string; }) => void;

    constructor() {
        super();
        this.viewFactory = new ViewFactory();

        this.apiClient = new GenericApiClient(new ApiInteractor());
        this.authComponent = new AuthComponent(new TokenInteractor(this.apiClient));

        this.apiClientAuth = new GenericApiClient(new ApiInteractor(this.authComponent));

        this.captchaInteractor = new CaptchaInteractor(this.apiClient);
        this.formValidationInteractor = new FormValidationInteractor(this.authComponent);
        this.liveInteractor = new LiveInteractor(this);

        this.appView = new AppView();

        this.liveNavComponent = new LiveNavigationComponent(
            this.liveInteractor, this.viewFactory, this.appView);
    }
    activate(): void {

        this.viewFactory.setFactoryFn('live', async (vEl) => new LiveView(vEl, this.liveNavComponent));
        this.viewFactory.setFactoryFn('inject', this.liveNavComponent.buildInjectView.bind(this.liveNavComponent));
        this.viewFactory.setFactoryFn('liveLoadingError', async (vEl) => this.liveNavComponent.buildLiveLoadingErrorView(vEl));
        //
        this.viewFactory.setFactoryFn('captcha', async (vEl) => new CaptchaView(vEl, this.captchaInteractor));
        this.viewFactory.setFactoryFn('form', async (vEl) => new FormView(vEl as HTMLFormElement, this.formValidationInteractor));
        this.viewFactory.setFactoryFn('alerts', async (vEl) => new AlertsView(vEl));
        this.viewFactory.setFactoryFn('progressBar', async (vEl) => this.liveNavComponent.buildProgressBarView(vEl));

        //
        this.appView.getMetaTags(this.processMetaParams.bind(this));
        this.authComponent.activate();
        this.liveNavComponent.activate();

        this.appView.instantiateChildViews(this.viewFactory).then(() => {
            this.appView.init();
        });
    }
    destroy(): void {
        this.appView.dispose();
        this.authComponent.destroy();
        this.liveNavComponent.destroy();
    }
    processMetaParams(meta: { [K: string]: string; }) {
        processMetaParamsCallbackMap(meta, {
            'access_token': this.authComponent.setAccessTokenFromJsonStr.bind(this.authComponent),
            'refresh_token': (content) => this.authComponent.refreshToken = content,
            'api_url': (content) => this.apiClient.apiUrl = String(content),
        });
        this.metaCallback?.call(null, meta);
    }
}
