
export function processMetaParamsCallback(meta: {
    [K: string]: string;
}, callbackFn: ((name: string, content: string) => void)): void {
    let name, content;
    for (name in meta) {
        content = meta[name];
        if (name && content) {
            callbackFn.call(null, name, content);
        }
    }
}
export function processMetaParamsCallbackMap(meta: {
    [K: string]: string;
}, callbackMap: {
    [K: string]: ((content: string) => void);
}): void {
    processMetaParamsCallback(meta, (name, content) => {
        if (name in callbackMap) {
            callbackMap[name].call(null, content);
        }
    });
}

export function parseMetaTags(headEl: HTMLElement): { [K: string]: string; } {
    const metaTags = headEl.querySelectorAll('meta[name][content]');
    const out: { [K: string]: string; } = {};
    let tag, name, content;
    for (tag of metaTags) {
        content = tag.getAttribute('content'),
            name = tag.getAttribute('name');
        if (name && content) {
            out[name] = content;
        }
    }
    return out;
}
