import { View } from "../../views/View";

export class CopyTextInputView extends View<any> {
    constructor(el: HTMLElement) {
        super('copyTextInput', el);
    }
    public init(): void {
        super.init();
        if (this.el) {
            const btnEl = this.el.querySelector<HTMLButtonElement>('button.copy-btn')!;
            btnEl.addEventListener('click', this.doCopyText.bind(this));
        }
    }
    public dispose(): void {
        super.dispose();
    }
    private doCopyText(): void {
        if (this.el) {
            const btnEl = this.el.querySelector<HTMLButtonElement>('button.copy-btn')!;
            const inputEl = this.el.querySelector<HTMLInputElement>('input[type=text].text-input')!;

            inputEl.focus();
            inputEl.select();
            inputEl.setSelectionRange(0, inputEl.value.length);
            navigator.clipboard.writeText(inputEl.value);

            if ('copiedText' in this.el.dataset) {
                btnEl.textContent = this.el.dataset.copiedText ?? '';
                btnEl.classList.add('copied');
            }
        }
    }
}
