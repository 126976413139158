import { CaptchaInteractor } from "../../interactors/CaptchaInteractor";
import { Presenter } from "../../mvp/Presenter";
import { CaptchaView } from "../view/CaptchaView";

type CaptchaModel = {
    target: string;
    loading: boolean;
};

export class CaptchaPresenter extends Presenter<CaptchaView, CaptchaModel> {
    constructor(target: string,
        protected readonly captchaInteractor: CaptchaInteractor) {
        super({ target, loading: false });
    }

    public onReady(view: CaptchaView) {
        super.onReady(view);
    }

    public async reloadImage() {
        console.log('reloading...');
        if (this.model?.loading) {
            return;
        }
        this.model!.loading = true;
        try {
            const result = await this.captchaInteractor.getCaptchaImage(this.model!.target);
            this.ifViewAttached(v => {
                v.setCaptcha(result.challengeString, result.image);
            });
        } finally {
            setTimeout(() => {
                this.model!.loading = false;
            }, 1000);
        }
    }
}
