import { View } from "./View";

type Alert = {
    messageText: string;
    class: string;
};
type AlertsList = Array<Alert>;

export class AlertsView extends View {

    constructor(el: HTMLElement) {
        super('alerts', el);
    }

    setAlerts(alertsList: AlertsList): void {
        const liEls = alertsList.map(a => {
            const li = document.createElement('li');
            li.classList.add('alert');
            a.class && li.classList.add('alert-' + a.class);
            li.textContent = a.messageText;
            return li;
        });
        this.el.replaceChildren(...liEls);
    }
}
