import { API_URL } from '../config';
import { ApiError, ApiMethod } from './enums/ApiEnums';
import { ApiInteractor } from './interactors/ApiInteractor';

export class ApiInvocationError extends Error {
    constructor(public isUnexpectedException: boolean,
        public errorCode: ApiError,
        public message: string) {
        super(message);
        Object.setPrototypeOf(this, ApiInvocationError.prototype);
    }
}

type ApiCallOptions = {
    method: ApiMethod;
    body?: FormData;
};

export class GenericApiClient {
    public apiUrl: string = API_URL;
    constructor(public readonly apiInteractor: ApiInteractor) {
    }
    async callMethod<T = any>(
        options: ApiCallOptions): Promise<T> {
        const data = await this.apiInteractor.invoke<T>({
            apiUrl: this.apiUrl,
            postData: options.body,
            apiMethod: options.method
        });
        return data;
    }
}
