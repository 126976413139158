import { FormValidationInteractor } from '../../interactors/FormValidationInteractor';
import { Presenter } from '../../mvp/Presenter';
import { FormModel } from '../model/FormModel';
import { FormView } from '../view/FormView';

export class FormPresenter extends Presenter<FormView, FormModel> {

    constructor(protected readonly formValidationInteractor: FormValidationInteractor,
        public model?: FormModel) {
        super(model ?? new FormModel());
    }
    public onReady(view: FormView) {
        super.onReady(view);
        view.readFormData();
        this.model!.allowSubmit = !!!this.model!.validate;
    }
    public updateFormData(newData: FormData): void {
        this.model!.formData = newData;
    }
    public async validate() {
        if (!this.model?.validate) {
            this.model!.allowSubmit = true;
            return;
        } else if (this.model?.posting) {
            return;
        }
        this.model!.posting = true;
        this.model!.allowSubmit = false;

        this.ifViewAttached(v => {
            v.readFormData();
            v.clearErrorAlerts();
        });

        try {
            const result = await this.formValidationInteractor.validateForm(this.model!);
            if (result.ok) {
                this.model!.allowSubmit = true;
                this.ifViewAttached(v => v.doSubmit());
            } else {
                this.ifViewAttached(v => v.showValidationError(result));
            }
        } catch (e) {
            this.ifViewAttached(v => v.showError(e));
        } finally {
            this.model!.posting = false;
        }
    }

}
