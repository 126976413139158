import { View } from "../../views/View";

export class NavView extends View<any> {
    protected linksArray?: Array<HTMLLinkElement>;
    constructor(el: HTMLElement) {
        super('nav', el);
    }
    
    public init(): void {
        super.init();
        this.linksArray = Array.from(this.el.querySelectorAll<HTMLLinkElement>('a[data-name]'));
    }
    public dispose(): void {
        super.dispose();
        delete this.linksArray;
    }
    setActive(nameArg: string): void {
        this.linksArray?.forEach(lnk => {
            if (nameArg === lnk.dataset.name) {
                lnk.classList.add('active');
            } else {
                lnk.classList.remove('active');
            }
        });
    }
}
