import { View } from "./View";
import { parseMetaTags } from '../helpers/MetaHelper';

export class AppView extends View {
    constructor() {
        super('app', document.body, undefined, true);
    }

    public getMetaTags(callback: ((meta: { [K: string]: string; }) => void)) {
        callback(parseMetaTags(document.head));
    }
    public async setTitle(title: string) {
        document.title = title;
    }

}

