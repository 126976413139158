import { FormView } from '../../forms/view/FormView';
import { FormValidationInteractor } from '../../interactors/FormValidationInteractor';

type GeoData = {
    regions: Array<{
        id: number;
        name_uk: string;
    }>;
    cities: Array<{
        id: number;
        name_uk: string;
        priority: number;
        region_id: number;
    }>;
};

export class QuickSearchFormView extends FormView {
    constructor(el: HTMLFormElement, formValidationInteractor: FormValidationInteractor) {
        super(el, formValidationInteractor);
    }
    public init(): void {
        super.init();
        // Search Form
        let quickSearchForm = this.el as HTMLFormElement,
            flowDD: HTMLSelectElement,
            regionDD: HTMLSelectElement,
            cityDD: HTMLSelectElement,
            geoData: GeoData | undefined;

        function displayFlowFields(flow: string): void {
            quickSearchForm?.querySelectorAll<HTMLDivElement>('div[data-flow]').forEach((div) => {
                div.style.display = 'none';
                if (div.dataset.flow === flow) {
                    div.style.display = 'block';
                }
            });
        }

        if (quickSearchForm) {
            flowDD = quickSearchForm.querySelector<HTMLSelectElement>('select[name="searchForm[FLOW]"]')!;
            regionDD = quickSearchForm.querySelector<HTMLSelectElement>('select[name="searchForm[REGION]"]')!;
            cityDD = quickSearchForm.querySelector<HTMLSelectElement>('select[name="searchForm[CITY]"]')!;

            // parse geo data
            const scriptEl = quickSearchForm.querySelector<HTMLScriptElement>('script#geo_dropdown_data_json');
            geoData = JSON.parse(scriptEl!.innerText);

            flowDD.addEventListener('change', function() {
                displayFlowFields(flowDD.value);
            });
            displayFlowFields(flowDD.value);

            regionDD.addEventListener('change', function() {
                const value = parseInt(this.value);
                if (geoData && 'cities' in geoData) {
                    const citiesList = geoData.cities.filter(city => value === city.region_id)
                        .sort((a, b) => a.priority > b.priority ? -1 : +1);
                    // cityDD.replaceChildren();
                    cityDD.options.length = 0;
                    citiesList.map(city => new Option(city.name_uk, String(city.id)))
                        .forEach(opt => cityDD.options.add(opt));

                }
            });
        }

    }
    public dispose(): void {
        super.dispose();
    }
}

