import { FormValidationInteractor } from '../../interactors/FormValidationInteractor';
import { MvpView } from '../../mvp/MvpView';
import { AlertsView } from '../../views/AlertsView';
import { View } from '../../views/View';
import { FormModel } from '../model/FormModel';
import { FormPresenter } from '../presenter/FormPresenter';

type FormState = {
    validate?: boolean;
};

type ValidationResult = {
    ok: boolean;
    errorMessage: string;
};

export class FormView extends MvpView<FormPresenter, FormState> {
    protected submitEventListener = (ev: SubmitEvent) => this.handleSubmit.call(this, ev);
    protected alertsView?: AlertsView;

    constructor(el: HTMLFormElement,
        protected formValidationInteractor: FormValidationInteractor) {
        super('form', el, undefined, {
            validate: false
        });
        this.subTree = true;
    }
    protected createPresenter(): FormPresenter {
        if (this.el instanceof HTMLFormElement) {
            const model = new FormModel();
            model.handlerUrl = this.el.getAttribute('action')!;
            model.validate = !!this.state?.validate;
            return new FormPresenter(this.formValidationInteractor, model);
        } else {
            throw new TypeError('form view: given element is not <form> tag');
        }
    }
    public init(): void {
        let alertsEl, alertsView;
        console.log('form init');
        //
        if (this.el.classList.contains('validate') ||
            this.el.getAttribute('data-validate') === 'perform') {
            this.state!.validate = true;
        }

        //
        super.init();
        //
        this.el.addEventListener('submit', this.submitEventListener);
        // try to find AlertsView
        if ((alertsEl = this.el.querySelector<HTMLElement>('*[data-view="alerts"]')) &&
            (alertsView = View.ofEl(alertsEl)) && (alertsView instanceof AlertsView)) {
            this.alertsView = alertsView;
        }
    }
    public dispose(): void {
        super.dispose();
        this.el.removeEventListener('submit', this.submitEventListener);
    }
    public readFormData() {
        this.presenter?.updateFormData(new FormData(this.el as HTMLFormElement));
    }
    public doSubmit(): void {
        console.log('submit now!!!');
        (this.el as HTMLFormElement).requestSubmit();
    }
    public showValidationError(result: ValidationResult): void {
        if (!result.ok) {
            if (this.alertsView) {
                this.alertsView.setAlerts([
                    { class: "error", messageText: result.errorMessage }
                ]);
            }
            result.errorMessage?.length && alert(result.errorMessage);
        }
    }
    public showError(e: any): void {
        alert(`Unable to validate form: ${e?.message}`);
    }
    public clearErrorAlerts() {
        this.alertsView?.setAlerts([]);
    }

    protected handleSubmit(ev: SubmitEvent) {

        console.log('handle Form Submit...');
        if (this.presenter?.model?.validate) {
            this.presenter?.validate();
        }
        if (!this.presenter?.model?.allowSubmit) {
            ev.stopPropagation();
            ev.preventDefault();
            console.log('submitting disallowed (probably validation errors is here)');
        }
    }
}
