import { LiveNavigationComponent } from "../components/internal/LiveNavigationComponent";
import { View } from "./View";

export const LIVE_FORM_SELECTOR = 'form.live';
export const LIVE_LINK_SELECTOR = 'a.live[href]';

function urlAttr(el: Element, attr: "action" | "href"): URL {
    return new URL(el.getAttribute(attr)!, document.location.href);
}

export class LiveView extends View {
    private click = this.handleClickEvent.bind(this);
    private submit = this.handleFormSubmitEvent.bind(this);

    constructor(el: HTMLElement,
        protected readonly liveNavigationComponent: LiveNavigationComponent) {
        super('live', el, undefined, true);
    }

    public init() {
        super.init();
        console.log('live init');
        this.el.addEventListener('submit', this.submit);
        this.el.addEventListener('click', this.click);
    }
    public dispose(): void {
        super.dispose();
        this.el.removeEventListener('submit', this.submit);
        this.el.removeEventListener('click', this.click);
    }

    private handleFormSubmitEvent(ev: SubmitEvent) {
        const el = ev.target as HTMLFormElement;
        let method: string | undefined;
        if (el &&
            el.matches(LIVE_FORM_SELECTOR)) {
            ev.preventDefault();
            ev.stopPropagation();
            if ((method = el.getAttribute('method')?.toUpperCase()) === "GET" ||
                method === "POST") {
                this.liveNavigationComponent.navigateTo({
                    url: urlAttr(el, 'action'),
                    method,
                    data: new FormData(el),
                    pushState: true
                });
            } else {
                throw new Error('unsupported form "method": ' + method);
            }
        }
    }
    private handleClickEvent(ev: MouseEvent) {
        const { target } = ev;
        let el;
        if ((el = target instanceof Element ? target : null) &&
            (el = el?.closest(LIVE_LINK_SELECTOR))) {
            // TODO проверить origin из адреса куда переходят и сравнить, чтобы совпадал с текущим (или получим ошибку в pushState)
            ev.preventDefault();
            ev.stopPropagation();

            return this.liveNavigationComponent.navigateTo({
                url: urlAttr(el, 'href'),
                method: "GET",
                pushState: true,
            });
        }
    }
}


