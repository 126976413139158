
export enum ApiMethod {
    GET_CAPTCHA = 1000,
    REFRESH_TOKEN = 5100,
    CURRENT_USER_INFO = 5200,    
};

export enum ApiError {
    ERR_UNEXPECTED_EXCEPTION = 100,
    ERR_NO_SUCH_METHOD = 900,
    ERR_WRONG_ACCESS_TOKEN_TYPE = 1000,
    ERR_INVALID_ACCESS_TOKEN = 1001,
};
