import { MvpView } from '../../mvp/MvpView';

export class TransparentPostLinkView extends MvpView<any> {
    constructor(el: HTMLElement) {
        super('transparentPostLink', el);
        this.subTree = true;
    }
    public init(): void {
        super.init();

        // Transparent Post links
        this.el.addEventListener('click', function(ev) {
            let itemEl;
            if (ev.target instanceof HTMLElement &&
                (itemEl = ev.target.closest('div.listing-item[data-post]'))) {
                ev.preventDefault();
                ev.stopPropagation();
                location.href = itemEl.getAttribute('data-post')!;
            }
        });

    }
    public dispose(): void {
        super.dispose();
    }
}
