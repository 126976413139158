import { View } from './View';
export type ViewFactoryAsyncFnType = (vEl: HTMLElement, name: string) => Promise<View | undefined>;

export class ViewFactory {
    protected _isLoading?: boolean;
    protected factoryFnMap: Map<string, ViewFactoryAsyncFnType>;

    constructor(protected viewFactoryFn?: ViewFactoryAsyncFnType) {
        this.factoryFnMap = new Map();
    }
    public setFactoryFn(name: string, fn: ViewFactoryAsyncFnType): void {
        this.factoryFnMap.set(name, fn);
    }
    public async build(name: string,
        vEl: HTMLElement): Promise<View> {
        let v, providerFn;
        this._isLoading = true;
        try {
            if ((providerFn = this.factoryFnMap.get(name))) {
                v = await providerFn.call(null, vEl, name);
            } else if (this.viewFactoryFn) {
                v = await this.viewFactoryFn.call(null, vEl, name);
            }
        } finally {
            this._isLoading = false;
        }
        if (!v) {
            return new View(name, vEl);
        }
        return v;
    }
    get isLoading() {
        return !!this._isLoading;
    }
}
