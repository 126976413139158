import { MvpView } from '../../mvp/MvpView';

export class SiteHeaderView extends MvpView<any> {
    constructor(el: HTMLElement) {
        super('siteHeader', el);
        this.subTree = true;
    }
    public init(): void {
        super.init();

        // Menu Dropdown Toggle
        let triggerEl;
        if ((triggerEl = this.el.querySelector('.menu-trigger'))) {
            const nav = this.el.querySelector<HTMLDivElement>('.header-area .nav')!;
            triggerEl.addEventListener('click', function(this: HTMLLinkElement) {
                this.classList.toggle('active');

                if (this.classList.contains('active')) {
                    nav.style.display = 'block';
                } else {
                    nav.style.display = 'none';
                }
            });
        }

    }
    public dispose(): void {
        super.dispose();
    }
}
