import { GenericApiClient } from "../ApiClient";
import { ApiMethod } from "../enums/ApiEnums";

type GetCaptchaImageResponse = {
    "CHALLENGE": string;
    "IMAGE": {
        "MIME": string;
        "ENCODING": string;
        "CONTENT": string;
    };
};

type CaptchaDetails = {
    challengeString: string;
    image: HTMLImageElement;
};

export class CaptchaInteractor {
    constructor(protected readonly apiClient: GenericApiClient) {
    }
    async getCaptchaImage(target: string): Promise<CaptchaDetails> {
        let body, result, image;
        body = new FormData();
        body.set('TARGET', target);
        result = await this.apiClient.callMethod<GetCaptchaImageResponse>({
            method: ApiMethod.GET_CAPTCHA,
            body
        });
        image = document.createElement('img');
        image.setAttribute('src', `data:${result['IMAGE']['MIME']};${result['IMAGE']['ENCODING']},${result['IMAGE']['CONTENT']}`);
        return {
            challengeString: result['CHALLENGE'] ?? "",
            image
        };
    }
}

